<template>
  <div class="py-6 px-6 mt-2 w-full h-full bg-white rounded shadow ">
    <section v-if="lastTrip.noLastTrip" class="w-full h-full">
      <div class="text-xl font-semibold uppercase text-oDark">
        Last Trip
      </div>
      <div
        class="flex flex-col justify-center items-center w-full"
        style="height: 100%"
      >
        <img
          src="@/assets/placeholder/riding-scooter.png"
          class="block object-cover object-center"
        />
        <div class="text-sm font-semibold text-center text-oGray">
          No Trip Taken!
        </div>
      </div>
    </section>

    <section v-else class="flex flex-col justify-between lg:flex-row">
      <!-- text -->
      <div class="w-full">
        <div class="flex justify-between items-center mb-6">
          <div class="text-xl font-semibold uppercase text-oDark">
            Last Trip
          </div>
        </div>

        <div class="grid grid-cols-1">
          <div class="grid grid-cols-1 md:col-span-2 sm:col-span-3">
            <div class="grid grid-cols-2 my-3">
              <div class="font-semibold text-14px text-oDark">
                Trip Status
              </div>

              <TripStatusButtonByFlag :value="lastTripStatus" />
            </div>

            <div class="grid grid-cols-2 my-3">
              <div class="font-semibold text-14px text-oDark">Trip ID</div>
              <router-link
                :to="{ name: 'ViewTrip', params: { id: lastTrip.id } }"
                target="_blank"
              >
                <div class="font-medium text-blue-500 text-14px">
                  #{{ lastTrip.id | onlyLastFive }}
                </div>
              </router-link>
            </div>

            <div class="grid grid-cols-2 my-3">
              <div class="font-semibold text-14px text-oDark">Trip Date</div>
              <div class="font-medium text-gray-500 text-14px">
                {{ getFormattedDateTime(lastTrip.created_at) }}
              </div>
            </div>

            <div class="grid grid-cols-2 my-3">
              <div class="font-semibold text-14px text-oDark">
                Vehicle QR
              </div>
              <router-link
                :to="{
                  name: 'ViewVehicleProfile',
                  params: { id: lastTrip.bike_id },
                }"
                target="_blank"
              >
                <div class="font-medium text-blue-500 text-14px">
                  {{ lastTrip.bike_qr_code }}
                </div>
              </router-link>
            </div>

            <div class="grid grid-cols-2 my-3">
              <div class="font-semibold text-14px text-oDark">
                Invoice ID
              </div>
              <router-link
                :to="{
                  name: 'ViewTransaction',
                  params: { id: lastTrip.invoice_id },
                }"
                target="_blank"
              >
                <div class="font-medium text-blue-500 text-14px">
                  #{{ lastTrip.invoice_id | onlyLastFive }}
                </div>
              </router-link>
            </div>

            <!-- trip-photo -->
            <div v-if="!lastTrip.parking_pic" class="grid grid-cols-2 my-3">
              <div class="font-semibold text-14px text-oDark">
                Trip Photo
              </div>
              <div class="font-medium text-14px">
                N/A
              </div>
            </div>
            <!-- /trip-photo -->
          </div>
        </div>
      </div>
      <!-- /text -->

      <!-- pic -->
      <div
        class="overflow-hidden w-32 h-32 rounded-lg shadow-md flex justify-start mt-2 mb-2"
        v-if="lastTrip.parking_pic"
      >
        <img
          :src="lastTrip.parking_pic"
          @click="image_viewing_index = 0"
          alt="parking photo"
          class="object-cover h-32 rounded-lg cursor-pointer"
        />
        <!-- <img
          src="https://picsum.photos/200/300"
          alt=""
          class="object-cover w-full h-full"
        /> -->
      </div>
      <!-- /pic -->
    </section>
    <CoolLightBox
      effect="fade"
      :items="getTripParkingImage"
      :index="image_viewing_index"
      :useZoomBar="true"
      @close="image_viewing_index = null"
    />
  </div>
</template>

<script>
import { TripStatusButtonByFlag } from '@/composites/trip'
import { getFormattedDateTime } from '@/utils/datetime'
import CoolLightBox from 'vue-cool-lightbox'
export default {
  name: 'LastTripBlock',
  props: {
    lastTrip: {
      type: Object,
      required: true,
    },
  },

  components: {
    TripStatusButtonByFlag,
    CoolLightBox,
  },

  data() {
    return {
      image_viewing_index: null,
    }
  },
  computed: {
    lastTripStatus() {
      return this.$store.getters['lastTrip/getStatus']
    },
    getTripParkingImage() {
      return [{ src: this.lastTrip.parking_pic }]
    },
  },

  methods: {
    getFormattedDateTime,
  },
}
</script>
