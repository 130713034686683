var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"grid grid-cols-2 my-3"},[_c('div',{staticClass:"font-semibold text-14px text-oDark"},[_vm._v(" Profile Status ")]),_c('div',{staticClass:"font-medium text-gray-500 text-14px"},[_c('t-dropdown',{attrs:{"variant":"smActionsLeft"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
            var mousedownHandler = ref.mousedownHandler;
            var focusHandler = ref.focusHandler;
            var blurHandler = ref.blurHandler;
            var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"profile-status-badge focus:outline-none",class:_vm.currentStatus,attrs:{"aria-label":"Profile Status","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_vm._v(" "+_vm._s(_vm.getCurrentStatusText)+" "),_c('i',{staticClass:"mt-1 ml-1 fas fa-chevron-down"})])]}},{key:"default",fn:function(ref){
            var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-px bg-white rounded-md shadow-md",on:{"click":function($event){return hide()}}},[(_vm.currentStatus !== 'H')?_c('t-dropdown-item',{on:{"click":function($event){return _vm.showDialog('H')}}},[_vm._v(" Hold ")]):_vm._e(),(_vm.currentStatus !== 'A')?_c('t-dropdown-item',{on:{"click":function($event){return _vm.action('A')}}},[_vm._v(" Activate ")]):_vm._e(),(_vm.currentStatus !== 'D')?_c('t-dropdown-item',{on:{"click":function($event){return _vm.showDialog('D')}}},[_vm._v(" Deactivate ")]):_vm._e(),_c('t-dropdown-item',{on:{"click":function($event){return _vm.actionForceSync()}}},[_vm._v(" Force Sync Status ")])],1)]}}])})],1)])]),_c('t-modal',{attrs:{"name":"holdOrDeactivateUserAccount","variant":"confirmation","hideCloseButton":true,"disableBodyScroll":true,"escToClose":!_vm.isLoading,"clickToClose":!_vm.isLoading}},[_c('section',{staticClass:"px-4 pt-4 text-2xl font-extrabold text-center"},[_vm._v(" "+_vm._s(_vm.pendingAction === 'D' ? 'Deactivate' : 'Hold')+" Account ")]),_c('div',{staticClass:"px-5 pt-5"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.confirmDialog())}}},[_c('AppInput',{attrs:{"rules":"required","type":"richselect","name":"reason","label":"Reason","placeholder":"Select a reason","options":_vm.pendingAction === 'H' ? _vm.reasons.hold : _vm.reasons.deactivate,"hide-search-box":""},model:{value:(_vm.models.reason),callback:function ($$v) {_vm.$set(_vm.models, "reason", $$v)},expression:"models.reason"}}),_c('AppInput',{attrs:{"rules":"","label":"Remark","type":"textarea","name":"remark"},model:{value:(_vm.models.remark),callback:function ($$v) {_vm.$set(_vm.models, "remark", $$v)},expression:"models.remark"}}),_c('button',{ref:"holdOrDeactivateSubmitButtonRef",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]}}])})],1),_c('section',{staticClass:"flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"},[_c('AppButton',{class:"w-1/2",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.hideDialog()}}},[_vm._v(" Cancel ")]),_c('AppButton',{class:"w-1/2",attrs:{"variant":"primary","isLoading":_vm.isLoading},on:{"click":function($event){return _vm.$refs.holdOrDeactivateSubmitButtonRef.click()}}},[_vm._v(" "+_vm._s(_vm.pendingAction === 'D' ? 'Deactivate' : 'Hold')+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }