<template>
  <div>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <template v-if="isLoaded">
      <div
        class="grid lg:space-x-2 lg:space-y-2 lg:grid-cols-2 md:grid-cols-1 gap-2"
      >
        <info-grid
          title="Basic Info"
          grid-class="grid-cols-1"
          :data="getProfile"
          :edit-button="false"
          @edit="handleEditing()"
        >
          <template v-slot:subheader>
            <ProfileStatusDropdown
              :status="getProfileStatus"
              :userId="id"
              @refresh="forceRefresh"
            />
          </template>

          <!-- <template v-slot:subheader>
        <div
          class="flex flex-col my-3 space-y-4 md:flex-row md:items-center md:space-y-0 md:space-x-4"
        >
          <summary-card
            title="Balance"
            :value="getBalance"
            icon="fas fa-money-bill-wave"
            variant="orange"
            size="large"
          />
          <summary-card
            title="Total Ride Taken"
            :value="getTotalRides"
            icon="fas fa-bicycle"
            variant="purple"
            size="large"
          />
          <summary-card
            title="Total Calorie Burned"
            :value="getTotalCalories"
            icon="fas fa-fire"
            variant="green"
            size="large"
          />
        </div>
      </template> -->
          <!-- <template v-slot:footer="{ typographyTwo, typographyThree }">
            <div class="grid grid-cols-2 my-2">
              <div :class="typographyTwo">Fleets</div>
              <template v-for="(item, index) in getFleets">
                <div :class="typographyThree" :key="index">{{ item }}</div>
              </template>
            </div>
          </template> -->
        </info-grid>
        <LastTripBlock :lastTrip="getLastTrip" />
      </div>
      <div
        class="grid lg:space-x-2  lg:space-y-2 lg:grid-cols-2 md:grid-cols-1 gap-2 mt-2"
      >
        <DeviceInfoBlock
          title="Device Info"
          grid-class="grid-cols-1 "
          :data="getDevice"
        />
        <FinancialBlock
          title="Financial Activity"
          grid-class="grid-cols-1 "
          :data="getFinancialInfo"
        />
      </div>
      <content-section class="mt-4">
        <div class="grid grid-cols-2 md:space-x-2 md:pr-2 lg:grid-cols-5">
          <!--  -->
          <div class="col-span-2 my-6">
            <oto-typography
              text="REFERRAL"
              variant="one"
              class="inline-block mb-5"
            />
            <div
              class="
                flex flex-col
                w-full
                mt-5
                sm:items-center
                md:flex-row md:mt-0
              "
            >
              <referral-code-ribbon :code="getReferralCode" />
              <!-- <summary-card-alt
              title="Total Earnings"
              :value="getTotalEarnings"
              icon="fas fa-money-bill-wave text-custom-green"
              size="normal"
              variant="green"
              class="mt-5 ml-0 md:ml-10 md:mt-0"
            /> -->
            </div>
          </div>
          <!--  -->
          <div class="col-span-3 my-6">
            <oto-typography
              text="HEALTH"
              variant="one"
              class="inline-block mb-5"
            />
            <!-- <div class="flex items-center mt-5 space-x-10 md:mt-0">
            <summary-card-alt title="Height" :value="getHeight" icon="fas fa-arrows-alt-v text-custom-blue" size="normal" variant="gray" />
            <summary-card-alt title="Weight" :value="getWeight" icon="fas fa-weight text-custom-blue" size="normal" variant="gray" />
          </div> -->
            <div
              class="
                grid
                h-auto
                space-y-4
                md:grid-cols-3 md:space-x-4 md:h-44 md:space-y-0
              "
            >
              <div
                class="
                  flex flex-col
                  justify-center
                  py-5
                  bg-gray-100
                  rounded-lg
                  px-7
                "
              >
                <i
                  class="mb-5 text-3xl text-blue-300 fas fa-ruler-vertical"
                ></i>
                <h5 class="mb-5 text-2xl font-bold text-gray-800">
                  {{ getHeight }}
                </h5>
                <p class="text-sm text-gray-600">Height</p>
              </div>
              <div
                class="
                  flex flex-col
                  justify-center
                  py-5
                  bg-gray-100
                  rounded-lg
                  px-7
                "
              >
                <i class="mb-5 text-3xl text-purple-400 fas fa-stopwatch"></i>
                <h5 class="mb-5 text-2xl font-bold text-gray-800">
                  {{ getWeight }}
                </h5>
                <p class="text-sm text-gray-600">Weight</p>
              </div>
              <div
                class="
                  flex flex-col
                  justify-center
                  py-5
                  bg-gray-100
                  rounded-lg
                  px-7
                "
              >
                <i class="mb-5 text-3xl text-orange-500 fas fa-fire"></i>
                <h5 class="mb-5 text-2xl font-bold text-gray-800">
                  {{ getCalories }}
                </h5>
                <p class="text-sm text-gray-600">Calories Burn</p>
              </div>
            </div>
          </div>
        </div>
      </content-section>
      <!-- <info-grid
      title="Payment Info"
      class="mt-2"
      grid-class="grid-cols-2 md:grid-cols-4"
      :data="getPaymentInfo"
      @edit="handleEditing()"
    /> -->
      <content-section class="mt-2">
        <div class="flex items-center justify-between">
          <oto-typography text="Documents" variant="one" />
          <oto-edit-button @click="handleEditing()" />
        </div>
        <!-- <div class="h-px my-4 bg-oLightestGray" /> -->
        <!-- <div v-if="getDocs"> -->
        <div v-for="(item, index) in getDocs" :key="index">
          <user-document
            :doc-id="item.id"
            :status="item.status"
            :status-title="item.status_title"
            :cover="item.cover"
            :images="item.images"
            :title="item.title"
            :subtitle="item.subtitle"
            @verify="(...args) => verifyDocs(...args)"
          />
        </div>
        <!-- </div>
      <div v-else class="text-center text-oGray">
        No Docs Availabel
      </div> -->
      </content-section>
    </template>
  </div>
</template>
<script>
// import dayjs from "dayjs";
import xMan from '@/utils/xMan'
// import { RiderUserConfig } from "@/config/RiderUserConfig";
import { RiderResource } from '@/resources/RiderResource'
import { IdentityCardConfig } from '@/config/IdentityCardConfig'
import InfoGrid from '@/components/layout/InfoGrid'
import ContentSection from '@/components/layout/ContentSection.vue'
import OtoTypography from '@/components/ui/OtoTypography'
import OtoEditButton from '@/components/ui/OtoEditButton'
// import SummaryCard from "@/components/cards/SummaryCard";
// import SummaryCardAlt from "@/components/cards/SummaryCardAlt";
import ReferralCodeRibbon from '@/components/cards/ReferralCodeRibbon'
import dayjs from 'dayjs'
import UserDocument from '@/components/ui/UserDocument'
import { mapGetters } from 'vuex'

import LastTripBlock from '@/composites/rider/profile/blocks/LastTripBlock'
import DeviceInfoBlock from '@/composites/rider/profile/blocks/DeviceInfoBlock'
import FinancialBlock from '@/composites/rider/profile/blocks/FinancialBlock'
import ProfileStatusDropdown from '../blocks/profile/ProfileStatusDropdown.vue'

export default {
  name: 'ViewRiderUserProfile',
  components: {
    InfoGrid,
    ContentSection,
    OtoTypography,
    OtoEditButton,
    // SummaryCard,
    // SummaryCardAlt,
    ReferralCodeRibbon,
    UserDocument,
    LastTripBlock,
    DeviceInfoBlock,
    FinancialBlock,
    ProfileStatusDropdown,
  },
  async created() {
    await this.$store.dispatch('riderdetails/fetchData', this.id)
    this.profile = this.$store.getters['riderdetails/getData']

    // Fail safe
    if (!this.profile) {
      await this.$store.dispatch('riderdetails/fetchData', this.id)
      this.profile = this.$store.getters['riderdetails/getData']
    } else {
      this.isLoaded = true
    }

    // this.isLoading = true;
    // await this.$http
    //   .get(RiderUserConfig.api.single(this.id))
    //   .then(res => {
    //     console.log("rider-user-profile", res);
    //     this.profile = {
    //       ...res.data
    //     };
    //     this.isLoaded = true;
    //   })
    //   .catch(err => {
    //     console.log("rider-user-profile-err", err);
    //   })
    //   .finally(() => {
    //     this.isLoading = false;
    //   });
  },
  data() {
    return {
      isLoaded: false,
      isLoading: false,
      symbol: '$',
      org_user: {},
      fallbackText: '--',
      profile: {
        ...RiderResource,
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    id() {
      return this.$route.params.id
    },
    getAvatar() {
      return this.profile.profile.profile_pic || null
    },
    getBalance() {
      return this.profile.profile.balance
        ? this.symbol + this.profile.profile.balance
        : `${this.symbol}0.00`
    },
    getTotalRides() {
      return this.profile.profile.num_of_trips || '0'
    },
    getTotalCalories() {
      return this.profile.profile.total_calories || '0.00'
    },
    getTotalEarnings() {
      return this.profile.profile.earning_from_invitations
        ? this.symbol + this.profile.profile.earning_from_invitations
        : `${this.symbol}0.00`
    },
    getHeight() {
      return this.profile.profile.height
        ? `${this.profile.profile.height} '`
        : this.fallbackText
    },
    getWeight() {
      return this.profile.profile.weight
        ? `${this.profile.profile.weight} Kg`
        : this.fallbackText
    },
    getCalories() {
      return parseFloat(this.profile.profile.total_calories) > 0
        ? `${this.profile.profile.total_calories} Cl`
        : this.fallbackText
    },
    getReferralCode() {
      return this.profile.invitation_code || ''
    },
    getBirthday() {
      if (this.profile.profile && this.profile.profile.birthday) {
        return dayjs(this.profile.profile.birthday).format('DD MMM, YYYY')
      }
      return '--'
    },
    // getLastTripImage() {
    //   return this.profile.last_trip.parking_pic ? this.profile.last_trip.parking_pic : require("@/assets/img/empty.png")
    // },
    getProfile() {
      let data = []
      data.push({
        text: 'Full Name',
        value: this.profile.full_name || this.fallbackText,
      })
      data.push({
        text: 'Phone Number',
        value: this.profile.phone_number || this.fallbackText,
      })
      data.push({
        text: 'Blacklisted',
        value: this.profile.is_ip_blocked ? 'Yes' : 'No' || this.fallbackText,
      })
      data.push({
        text: 'Date of Birth',
        value: this.getBirthday,
      })
      data.push({
        text: 'Job Type',
        value: this.getJobType(this.profile?.profile?.ranger_job_type),
      })
      data.push({
        text: 'Email',
        value: this.profile.email || this.fallbackText,
      })
      data.push({
        text: 'Gender',
        value: this.getGender(this.profile?.profile?.gender),
      })
      data.push({
        text: 'Country',
        value: this.profile.country || '--',
      })
      data.push({
        text: 'Language',
        value: this.profile.language || '--',
      })
      data.push({
        text: 'Fleet',
        value: this.profile?.profile?.nearby_fleet?.name || '--',
      })
      return data
    },
    getDevice() {
      let data = []
      data.push({
        text: 'Last Location',
        value: this.profile.last_location,
      })
      data.push({
        text: 'Last IP Address',
        value: this.profile.last_ip_address,
      })
      data.push({
        text: 'Device Fingerprint',
        value: this.profile?.profile?.app_device_fingerprint || '--',
      })
      data.push({
        text: 'Device Model',
        value: this.profile?.profile?.app_device_model || '--',
      })
      data.push({
        text: 'OS Version',
        value: this.profile?.profile?.app_os_version || '--',
      })
      data.push({
        text: 'App Version',
        value: this.profile?.profile?.app_version || '--',
      })
      return data
    },
    getFinancialInfo() {
      let data = []
      data.push({
        text: 'Balance',
        html: this.getFinancialBalance(),
      })
      data.push({
        text: 'Number of Refund',
        value: this.profile?.wallet_summary?.total_refund_count || '--',
      })
      data.push({
        text: 'Number of Disput',
        value: this.profile?.wallet_summary?.total_dispute_count || '--',
      })
      data.push({
        text: 'Number of Dispute Loss',
        value: this.profile?.wallet_summary?.total_dispute_loss_count || '--',
      })
      data.push({
        text: 'Number of Dispute Win',
        value: this.profile?.wallet_summary?.total_dispute_win_count || '--',
      })
      return data
    },
    getFleets() {
      let data = []
      if (Array.isArray(this.assigned_fleets)) {
        data = [...this.assigned_fleets]
      }
      if (data.length === 0) {
        data.push('--')
      }
      // console.log(data);
      return data
    },
    getPaymentInfo() {
      // todo: use xMan.asteriskify() for sensitive data
      let data = []
      data.push({
        text: 'Bank Account Name',
        value: this.profile?.payment_info?.account_name || this.fallbackText,
      })
      data.push({
        text: 'Bank Account No',
        value: this.profile?.payment_info?.account_number || this.fallbackText,
      })
      data.push({
        text: 'Branch',
        value: this.profile?.payment_info?.branch_name || this.fallbackText,
      })
      data.push({
        text: 'Country',
        value: this.profile?.payment_info?.country || this.fallbackText,
      })
      data.push({
        text: 'Routing Number',
        value: this.profile?.payment_info?.routing_number || this.fallbackText,
      })
      return data
    },
    getDocs() {
      let data = []
      // console.log("sd", data);
      let docs = this.profile.documents
      for (const key in docs) {
        // console.log(key, docs[key]);
        data.push({
          id: docs[key].id,
          title: docs[key].card_type,
          subtitle: docs[key].card_id,
          cover: docs[key].card_pic,
          status: docs[key].is_verified,
          status_title: docs[key].verification_status,
          images: [
            {
              title: docs[key].card_type,
              description: docs[key].card_id,
              src: docs[key].card_pic || null,
            },
            {
              title: docs[key].card_type,
              description: docs[key].card_id,
              src: docs[key].card_pic_back || null,
            },
          ],
        })
      }
      return data
    },
    getLastTrip() {
      if (this.profile.last_trip) {
        return this.profile.last_trip
      }
      return {
        noLastTrip: true,
      }
    },
    getProfileStatus() {
      console.warn({ object: this.profile.user_status })
      return this.profile && this.profile.user_status
        ? this.profile.user_status
        : 'D'
    },
  },
  mounted() {
    this.symbol = this.organizationInfo?.default_currency?.symbol || '$'
  },
  methods: {
    getFinancialBalance() {
      let balanceStr = ''
      if (
        this.profile &&
        this.profile.wallet_summary &&
        this.profile.wallet_summary.financial_accounts &&
        this.profile.wallet_summary.financial_accounts.length > 0
      ) {
        let data = this.profile.wallet_summary.financial_accounts.reduce(
          function(str, row) {
            return str + `<p>${row.currency.name} ${row.balance}</p>`
          },
          balanceStr
        )
        balanceStr = data
      }
      return balanceStr.replace(/(^,)|(,$)/g, '')
    },
    handleEditing() {
      if (this.$acl.canNotUpdate('riders')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'riders',
          })
        )
        return
      }
      alert('editing triggered')
    },
    handleDownloading() {
      alert('downloading triggered')
    },
    getDropdownClass(type) {
      if (type === 'nid') {
        if (this.isNidVerified) {
          return 'nid-verified'
        }
        return 'nid-unverified'
      }
    },
    getDropdownText(type) {
      if (type === 'nid') {
        return this.isNidVerified ? 'Verified' : 'Unverified'
      }
    },
    getJobType(job) {
      if (job) {
        return job === 'FULLTIME' ? 'Full Time' : 'Part Time'
      }
      return '--'
    },
    getGender(gender) {
      if (gender) {
        return gender === 'M' ? 'Male' : 'Female'
      }
      return '--'
    },
    async verifyDocs(id, isVerified, reason, remark, cb) {
      this.isLoading = true

      let data = new xMan({
        is_verified: isVerified,
        rejected_reason:
          !isVerified && typeof reason === 'string' ? reason : '',
        rejected_remark:
          !isVerified && typeof reason === 'string' ? remark : '',
      }).toFormData()

      await this.$http
        .post(IdentityCardConfig.api.update(id), data)
        .then(() => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Document Verification Status Update',
              text: isVerified
                ? 'Document has been verified successfully.'
                : 'Document has been rejected successfully.',
            },
            3000
          )

          cb && cb(isVerified)
        })
        .catch(() => {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Document Verification Status Update',
              text: 'Failed to update document verification status.',
            },
            3000
          )
          cb && cb()
        })
        .finally(() => {
          this.isLoading = false
        })

      return true
    },
    async forceRefresh() {
      await this.$store.dispatch('riderdetails/fetchData', this.id)
      this.profile = this.$store.getters['riderdetails/getData']
    },
  },
}
</script>
<style lang="scss" scoped>
.h-44 {
  height: 184px;
}
.docs-dropdown-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 110px;
  height: 18px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
}

.nid-verified {
  background-color: #09aa35;
}

.nid-unverified {
  background-color: #f67606;
}
</style>
