<template>
  <button
    :class="
      `flex items-center font-medium text-14px app-form-reset px-2 ${getStatusTextClass}`
    "
  >
    {{ getTripStatus }}
    <slot />
  </button>
</template>

<script>
import { getTripStatusColor, getTripStatusText } from '@/utils/TripStatus'

export default {
  name: 'TripStatusButtonByFlag',

  props: {
    value: {
      type: String,
      required: true,
    },

    variant: {
      type: String,
      default: 'badge',
      validator: (v) => {
        return ['badge'].includes(v)
      },
    },
  },

  computed: {
    getStatusTextClass() {
      const variant = getTripStatusColor(this.value)
      return `trip-status-pill ${variant}`
    },

    getTripStatus() {
      return getTripStatusText(this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '$trip-status';
</style>
