<template>
  <div class="py-6 px-6 mt-2 w-full h-full bg-white rounded shadow">
    <!-- start: title-section -->
    <div class="flex justify-between items-center mb-6">
      <div :class="typographyOne">{{ title }}</div>
    </div>
    <!-- end: title-section -->

    <!-- start: details-section -->
    <div class="grid" :class="gridClass" v-if="data">
      <div
        class="grid grid-cols-2 my-3"
        v-for="(item, index) in data"
        :key="index"
      >
        <div :class="typographyTwo">
          {{ item.text }}
        </div>

        <div
          :class="typographyThree"
          :style="item.color ? `color: ${item.color}` : ''"
          class="pr-1"
        >
          {{
            item.text == 'Last Location'
              ? item.value.replace(',', ', ')
              : item.value
          }}
        </div>
      </div>
    </div>
    <!-- start: details-section -->
  </div>
</template>

<script>
export default {
  name: 'DeviceInfoBlock',
  props: {
    title: { required: false, type: String, default: 'Title' },
    data: { required: false, type: Array },
    editButton: { required: false, type: Boolean, default: true },
    gridClass: { required: false, type: String, default: 'grid-cols-2' },
    footerClass: { required: false, type: String, default: '' },
    subheaderClass: { required: false, type: String, default: '' },
    type: { required: false },
  },

  data() {
    return {
      // title
      typographyOne: 'font-semibold uppercase text-oDark text-xl',
      // subtitle
      typographyTwo: 'font-semibold text-14px text-oDark',
      // subtitle->value
      typographyThree: 'font-medium text-14px text-gray-500',
    }
  },
  mounted() {},
  methods: {},
}
</script>
