var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.status === 'O')?_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var mousedownHandler = ref.mousedownHandler;
      var focusHandler = ref.focusHandler;
      var blurHandler = ref.blurHandler;
      var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"vld-parent trip-status-pill-button purple focus:outline-none",staticStyle:{"height":"35px","width":"170px"},attrs:{"aria-label":"On Trip Dropdown","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[(_vm.isLoading)?_c('loading',{attrs:{"height":25,"opacity":0,"color":"#fff","active":true,"loader":"bars","is-full-page":false}}):[_c('span',[_vm._v("On Trip")]),_c('i',{staticClass:"fas fa-chevron-down"})]],2)]}},{key:"default",fn:function(ref){
      var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-1 bg-white rounded-md shadow-md",on:{"click":function($event){return hide()}}},[_c('t-dropdown-item',{on:{"click":function($event){return _vm.executeAction('complete')}}},[_vm._v(" End Trip ")]),_c('t-dropdown-item',{staticClass:"text-oRed hover:bg-red-100",on:{"click":function($event){return _vm.executeAction('cancel')}}},[_vm._v(" Cancel Trip ")])],1)]}}],null,false,970039343)}):_c('TripStatusPillButton',{attrs:{"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }